import UserStore from './userStore'
import WindowStore from './windowStore'
import ModalStore from './modalStore'
import GroupingStore from './groupingStore'
import NavStore from './navStore'
import FeedbackStore from './feedbackStore'
import LocationStore from './locationStore'
import UserPageStore from './userPageStore'
import DateFilterStore from './dateFilterStore'

export default {
  userStore: new UserStore(),
  windowStore: new WindowStore(),
  modalStore: new ModalStore(),
  groupingStore: new GroupingStore(),
  navStore: new NavStore(),
  feedbackStore: new FeedbackStore(),
  locationStore: new LocationStore(),
  userPageStore: new UserPageStore(),
  dateFilterStore: new DateFilterStore()
}
