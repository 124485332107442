import * as React from 'react'
import CloseIconButton from '../../CloseIconButton'
import axios from 'axios'
import { Button, Text } from '@fluentui/react-northstar'
import { Icon } from 'office-ui-fabric-react'
import { Dropdown, ResponsiveMode } from 'office-ui-fabric-react/lib/Dropdown'

interface Props {
  content: React.ReactNode
  onClose: () => void
  backgroundColor?: any
  stopButtonColor?: any
  loading?: boolean
  demo?: boolean
  demoText?: string
  demoClick?: (string) => void
  demoLoading?: boolean
  refreshClick?: () => void
}

const DemoUserOptions = [
  { key: 'jordantaylor', text: 'Jordan Taylor' },
  { key: 'jamaldavis', text: 'Jamal Davis' },
  { key: 'michellewilliams', text: 'Michelle Williams' }
]

const DemoBanner: React.FC<Props> = ({
  content,
  onClose,
  backgroundColor,
  stopButtonColor,
  loading,
  demo,
  demoClick,
  demoText,
  demoLoading,
  refreshClick
}) => {
  return (
    <div
      className='d-n-768-max'
      style={{
        borderRadius: 0
      }}
    >
      <div
        style={{ backgroundColor: backgroundColor ? backgroundColor : 'rgb(91, 95, 199)', borderRadius: 0 }}
        className='banner-container-0-2 give-shadow-0-1'
      >
        <Button
          onClick={demoClick}
          content={'Change user'}
          primary
          loading={loading}
          style={{
            backgroundColor: stopButtonColor
          }}
        />
        <div className='banner-text-0-1'>{content}</div>
        {/*
        <Button
          onClick={() => {
            refreshClick()
          }}
          content={
            <Icon
              iconName='Refresh'
              style={{
                color: 'white',
                fontSize: '16px',
                fontWeight: 'bold'
              }}
            />
          }
          primary
          loading={loading}
          style={{ backgroundColor: stopButtonColor, marginRight: '20px', minWidth: '0px' }}
        />*/}
        <div className='banner-stop-button'>
          <Button
            onClick={onClose}
            content={'Switch to real account'}
            primary
            loading={loading}
            style={{
              backgroundColor: stopButtonColor
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default DemoBanner
