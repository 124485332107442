import * as React from 'react'
import { Text, Spinner, PrimaryButton } from 'office-ui-fabric-react'
import ModalStore from '../stores/modalStore'
import UserStore from '../stores/userStore'
import CloseIconButton from './CloseIconButton'
import { inject, observer } from 'mobx-react'
import i18n from 'i18next'

import { Button } from '@fluentui/react-northstar'

interface DemoReturnModalProps {
  modalStore: ModalStore
  userStore: UserStore
  onClick: () => void
}

interface DemoReturnModalState {
  loading: boolean
}

@inject('modalStore', 'userStore')
@observer
export class DemoReturnModal extends React.Component<DemoReturnModalProps, DemoReturnModalState> {
  constructor(props: DemoReturnModalProps) {
    super(props)
    this.state = {
      loading: false
    }
  }

  selectUser = () => {
    this.setState({ loading: true })

    setTimeout(() => {
      this.props.onClick()
      this.props.modalStore.closeModal()
      this.setState({ loading: false })
    }, 3000)
  }

  renderReturnModal = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Text
          style={{
            fontSize: 16,
            fontWeight: 500,
            marginBottom: 24,
            paddingLeft: 32,
            paddingRight: 32
          }}
        >
          You can always switch back to the demo environment by clicking the button shown in the picture
        </Text>
        <img src='assets/images/SwitchtoDemoImage.png' alt='return' style={{ height: 300, marginBottom: 24 }} />
      </div>
    )
  }

  render() {
    const { modalStore } = this.props

    return (
      <div style={{ padding: 32, paddingBottom: 30, position: 'relative', minHeight: '200px' }}>
        <div style={{ position: 'absolute', top: 20, right: 20 }}>
          <CloseIconButton onClick={() => modalStore.closeModal()} />
        </div>
        <Text className='modal-header-title' block>
          {i18n.t('DemoUserModal_ReturnToDemoHeader', 'Switch to real account')}
        </Text>
        {this.state.loading ? (
          <div
            className='bia'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <img style={{ width: '160px', height: '160px' }} src='assets/images/eclipse.gif' />
            {/* <Text>{i18n.t('DemoUserModal_SelectingUser', 'Selecting Demo User...')}</Text> */}
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            {this.renderReturnModal()}{' '}
            <Button
              content='Return'
              primary
              style={{ position: 'absolute', bottom: 20, right: 32, color: 'white' }}
              onClick={this.selectUser}
            />
          </div>
        )}
      </div>
    )
  }
}

export default DemoReturnModal
