import * as React from 'react'
import CloseIconButton from '../../CloseIconButton'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { Button, Text } from '@fluentui/react-northstar'
import { Icon } from 'office-ui-fabric-react'
import { Dropdown, ResponsiveMode } from 'office-ui-fabric-react/lib/Dropdown'
import i18n from 'i18next'

interface Props {
  content: React.ReactNode
  onClose: () => void
  backgroundColor?: any
  stopButtonColor?: any
  loading?: boolean
  demo?: boolean
  demoText?: string
  demoClick?: () => void
  demoLoading?: boolean
}

const DemoUserOptions = [
  { key: 'jordantaylor', text: 'Jordan Taylor' },
  { key: 'jamaldavis', text: 'Jamal Davis' },
  { key: 'michellewilliams', text: 'Michelle Williams' }
]

const ImpersonateBanner: React.FC<Props> = ({ content, onClose, backgroundColor, stopButtonColor, loading }) => {
  return (
    <div className='d-n-768-max'>
      <div
        style={{ backgroundColor: backgroundColor ? backgroundColor : 'rgb(91, 95, 199)' }}
        className='banner-container-0-2 give-shadow-0-1'
      >
        <div className='banner-text-0-1'>{content}</div>
        <div className='banner-stop-button'>
          <Button
            onClick={onClose}
            content={i18n.t('ImpersonateBanner_stopImpersonating', 'Stop Impersonating')}
            primary
            loading={loading}
            style={{
              backgroundColor: stopButtonColor
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ImpersonateBanner
