import { IconButton } from 'office-ui-fabric-react'
import * as React from 'react'
import i18n from 'i18next'

const CloseIconButton = (props: any) => {
  let iconSize = 12
  if (props.iconSize) {
    iconSize = props.iconSize
  }
  return (
    <IconButton
      style={{ color: props.color ? props.color : '#313131' }}
      iconProps={{ iconName: 'Cancel', style: { fontSize: iconSize } }}
      title={i18n.t('CloseIconButton_cancel', 'Cancel')}
      ariaLabel='Cancel'
      {...props}
    />
  )
}

export default CloseIconButton
