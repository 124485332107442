import { action, observable } from 'mobx'

export default class ModalStore {
  @observable width: number = 500
  @observable modal: any = {
    show: false,
    body: null,
    submit: false,
    haveOnDismiss: false,
    classNames: 'modalContainer',
    isLeftShow: null,
    isRightShow: null,
    preventRefreshOnClose: false
  }
  @observable submitForOneOnOne = false
  @observable onClose: any = () => {
    
  }

  @action showModal = (body, haveOnDismiss = true, width = 500, cn = '', preventRefreshOnClose = false) => {
    this.modal.show = true
    this.modal.haveOnDismiss = haveOnDismiss
    this.modal.body = body
    this.width = width
    this.modal.classNames = this.modal.classNames + ' ' + cn
    this.modal.preventRefreshOnClose = preventRefreshOnClose
  }

  @action setModalIsLeftShow = (bool, cb = () => {}) => {
    this.modal.isLeftShow = bool
    cb()
  }

  @action setModalIsRightShow = (bool, cb = () => {}) => {
    this.modal.isRightShow = bool
    cb()
  }

  @action closeModal = () => {
    this.modal.show = false
    this.modal.body = null
    this.modal.submit = true
    this.modal.onDismissOff = false
    this.submitForOneOnOne = true
    this.modal.isRightShow = null
    this.modal.isLeftShow = null
    this.modal.classNames = 'modalContainer'
    this.onClose()
  }

  @action closeModalWithoutRefresh = () => {
    this.modal.show = false
  }

  @action submitModal = () => {
    this.closeModal()
  }

  @action submitSetFalse = () => {
    this.modal.submit = false
  }

  @action submitTrue = () => {
    this.modal.submit = true
  }

  @action submitForOneOnOnePage = () => {
    this.submitForOneOnOne = false
  }

  @action setModalWidth = w => {
    this.width = w
  }

  @action setOnClose = f => {
    this.onClose = f
  }
}
/*
  <Modal
    isOpen={this.props.modalStore.modal.show}
    onDismiss={() => this.props.modalStore.closeModal()}
    isBlocking={false}
    containerClassName={'modalContainer'}
  >
    {this.props.modalStore.modal.body}
  </Modal>

  showModal -> modal acmasi gereken bir button vs. tiklandiginda calisir ve modal in body sini parametre alir
  closeModal -> onDissmis ve Cancel e tiklandiginda calisir body i bosaltir ve modal i kapatir
  submitModal -> modal in icinde form aciliyorsa ve o form doldurulup submit edildiyse modal i kapatir ve submit i true yapar
  submit true ise o form un ekledigi itemleri gunceller ->submitSetFalse yi calistiririz.
 */
/**
 * -> submitForOneOnOnePage in olma sebebi oneononepage de 2 tane submit in degisimini bekleyen reactive oluyor
 * -> bu da 2 sinden biri submiti false a cevirdiginde digerinde guncelleme olmuyor
 * -> cozum olarak da page icin ayri bir submit olusturdum item ayni sini kullaniyor 2 sinede farkli olmus oldu.
 * */
