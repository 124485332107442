import { action, observable } from 'mobx'

export default class WindowStore {
  @observable isMobile: Boolean = false
  @observable width
  @observable height

  @action setWidth(width) {
    this.width = width
    if (width < 768) {
      this.isMobile = true
    } else {
      if (this.isMobile === true) {
        this.isMobile = false
      }
    }
  }

  @action setHeight(height) {
    this.height = height
  }
}
