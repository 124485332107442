enum VIEW_MODES {
    AGENDA = 1,
    WORK_WEEK,
    WEEK
  }
  
  enum MEETING_TIME_STATUSES {
    NORMAL = 0,
    MEETING_TIME = 1,
    BEFORE_MEETING_TIME = 2
}

enum MEETING_RESPONSES {
    ACCEPTED = 0,
    TENTATIVE = 1,
    DECLINED = 2,
    NOT_RESPONDED = 3
}

  export { VIEW_MODES, MEETING_RESPONSES, MEETING_TIME_STATUSES }