import * as moment from 'moment'
import i18n from 'i18next'
import { intercomRoutes } from '../constants/routes'
import { matchPath } from 'react-router-dom'
import { mixpanelCustomEvent } from './mixPanelHelper'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const initBeacon = userData => {
  if (userData.tenant.settings.helpBeacon !== 2) {
    var INTERCOM_APP_ID = 'dfpxiwhb'

    let subscriptionStatus = 'free'
    if (userData.tenant.subscriptionStatus === 2) {
      subscriptionStatus = 'trial'
    } else if (userData.tenant.subscriptionStatus === 3) {
      subscriptionStatus = 'paid'
    }

    const region = cookies.get('x-region') || localStorage.getItem('x-region')

    window.Intercom('boot', {
      app_id: INTERCOM_APP_ID,
      name: userData.givenName ? userData.givenName : '',
      email: userData.mail,
      'Job Title': userData.jobTitle,
      'Subscription Status': subscriptionStatus,
      'Admin Role': userData.role === 2 ? 'admin' : 'user',
      created_at: moment(userData.createdAt).unix(),
      'CSM Responsible': userData.tenant ? userData.tenant.csmDetails?.responsibleCSM : '',
      'Sales Responsible': userData.tenant ? userData.tenant.subscriptionDetails?.responsibleSalesPerson : '',
      phone: userData.mobilePhone ? userData.mobilePhone : userData.businessPhones[0],
      'Graph User Count': userData.tenant ? userData.tenant.graphTotalUserCount : '',
      'Licensed User Count': userData.tenant ? userData.tenant.licensedUserCount : '',
      company: {
        company_id: userData.tenant._id,
        name: userData.tenant.displayName
      },
      region: region
    })
  }
}

const initBeaconShowListener = () => {
  window.Intercom('onShow', () => {
    const allowedParams = ['mode', 'feature']

    let route = intercomRoutes.find(r => matchPath(location.hash, { path: r.path, exact: true }))
    let paramKeyValuePairs = []
    if (route) {
      if (route.hasParams) {
        let paramKeys = route.path.split('/').reduce((acc, param, idx) => {
          if (param.includes(':')) {
            acc.push({ key: param.replace(':', '').replace('?', ''), index: idx })
          }
          return acc
        }, [])
        let paramValues = location.hash.split('/')
        paramKeyValuePairs = paramKeys.reduce((acc, param) => {
          if (allowedParams.includes(param.key)) acc[param.key] = paramValues[param.index]
          return acc
        }, {})
      }
      mixpanelCustomEvent('Intercom Click', {
        page: route.name,
        ...paramKeyValuePairs
      })
    }
  })
}

export { initBeacon, initBeaconShowListener }
