import axios from 'axios'
import { toJS } from 'mobx'
import { Icon, Text } from 'office-ui-fabric-react'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { RouteProps } from 'react-router-dom'
import useStore from '../hooks/useStore'
import UserStore from '../stores/userStore'
import { getCustomTeamsAppIdFromUserStore } from '../helpers/getCustomTeamsAppId'
import i18n from '../i18n'
import CloseIconButton from './CloseIconButton'
import { Button } from '@fluentui/react-northstar'

const UseInTeamsPopup = ({ userStore, windowStore }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false)

  useEffect(() => {
    componentDidMount()
  }, [])

  useEffect(() => {
    if (isPopupVisible) {
      calculatePopupPosition()
      trackPopupPosition()
    }

    return () => {
      window.removeEventListener('resize', calculatePopupPosition)
      const navbar = document.querySelector('.nav-new')
      if (navbar) {
        navbar.removeEventListener('scroll', calculatePopupPosition)
      }
    }
  }, [isPopupVisible])

  const componentDidMount = () => {
    setTimeout(function () {
      setIsPopupVisible(!userStore.teamsLogin && !userStore.userSettings.dontShowAgainUseInTeams)
    }, 5100)
  }

  const trackPopupPosition = () => {
    const navbar = document.querySelector('.nav-new')

    if (navbar) {
      navbar.addEventListener('scroll', calculatePopupPosition)
      window.addEventListener('resize', calculatePopupPosition)
    }
  }

  const calculatePopupPosition = () => {
    const navbarUseInTeamsButton = document.getElementById('navbar-use-in-teams-button')
    if (navbarUseInTeamsButton) {
      const buttonRect = navbarUseInTeamsButton.getBoundingClientRect()
      const useInTeamsPopup = document.getElementById('use-in-teams-popup')
      if (useInTeamsPopup) {
        const viewportHeight = window.innerHeight
        const popupHeight = useInTeamsPopup.scrollHeight
        if (viewportHeight < 365) {
          // make useInTeamsPopup not visible
          useInTeamsPopup.style.opacity = '0'
          return
        }
        // Calculate space available below the button
        const spaceBelowButton = viewportHeight - buttonRect.bottom

        // Check if the popup overflows below the screen
        const overflowAmount = popupHeight / 2 - spaceBelowButton - 25 // 25 is the triangle height

        if (overflowAmount > 0) {
          useInTeamsPopup.style.top = buttonRect.top - 168 - overflowAmount + 'px'

          // Get the triangle and move it accordingly
          const triangle = document.getElementById('use-in-teams-popup-triangle')
          triangle.style.top = 'calc(50% + 9px + ' + overflowAmount + 'px)'
        } else {
          useInTeamsPopup.style.top = buttonRect.top - 168 + 'px'
        }
        useInTeamsPopup.style.left = buttonRect.right + 10 + 'px'
        useInTeamsPopup.style.opacity = '1'
      }
    }
  }

  const handleCloseButtonClick = () => {
    setIsPopupVisible(false)
    userStore.setDontShowAgainUseInTeams(true)
  }

  const handleUseInTeamsButtonClick = () => {
    window.open(
      'https://teams.microsoft.com/l/entity/' + getCustomTeamsAppIdFromUserStore(userStore) + '/homePageEntity'
    )
    setIsPopupVisible(false)
    userStore.setDontShowAgainUseInTeams(true)
  }

  const handleDoNotAskAgainButtonClick = () => {
    setIsPopupVisible(false)
    userStore.setDontShowAgainUseInTeams(true)
    axios.post('/api/common/setDontShowAgainUseInTeams', { dontShowAgainUseInTeams: true })
  }

  if (!isPopupVisible) {
    return <></>
  }

  return (
    <div
      id='use-in-teams-popup'
      style={{
        padding: 16,
        backgroundColor: '#292B58',
        borderRadius: 8,
        position: 'absolute',
        zIndex: 1000,
        width: 266,
        opacity: 0,
        transition: 'opacity 0.3s'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
        <Icon iconName='TeamsLogo' style={{ color: '#FFF', marginRight: 8 }} />
        <Text style={{ fontSize: 14, fontWeight: 600, color: '#FFF' }}>
          {i18n.t('UseInTeamsPopup_boostYourTeamwork', 'Boost Your Teamwork')}
        </Text>
        <div style={{ flex: 1 }} />
        <Icon
          iconName='chromeClose'
          style={{ color: '#FFF', cursor: 'pointer', fontSize: 8 }}
          onClick={handleCloseButtonClick}
        />
      </div>
      <div
        style={{
          height: 168,
          marginBottom: 8
        }}
      >
        <img src='https://teamblee.blob.core.windows.net/teamflect/boost-your-teamwork.png' style={{ height: 168 }} />
      </div>
      <div style={{ marginBottom: 8 }}>
        <Text style={{ fontSize: 14, color: '#FFF', lineHeight: '20px' }}>
          {i18n.t(
            'UseInTeamsPopup_discoverTeamflectFeatures',
            'Discover Teamflect features in Microsoft Teams. Use in Teams for a seamless experience!'
          )}
        </Text>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <Button
          content={i18n.t('UseInTeamsPopup_doNotAskAgain', "Don't ask again")}
          primary
          onClick={handleDoNotAskAgainButtonClick}
          style={{ paddingLeft: 0, paddingRight: 0, width: '100%' }}
        />
        <Button
          content={i18n.t('UseInTeamsPopup_useInTeams', 'Use in Teams')}
          primary
          onClick={handleUseInTeamsButtonClick}
          style={{ paddingLeft: 0, paddingRight: 0, width: '100%' }}
        />
      </div>
      <div
        id='use-in-teams-popup-triangle'
        style={{
          position: 'absolute',
          top: 'calc(50% + 9px)',
          backgroundColor: '#292B58',
          height: 18,
          width: 18,
          rotate: '45deg',
          left: -5
        }}
      />
    </div>
  )
}

export default UseInTeamsPopup
