import { action, observable } from 'mobx'
import axios from 'axios'

class NavStore {
  @observable isNavOpen = false
  @observable sideBarOpen = true
  @observable isNavHidden = false
  @observable isMobileHeaderHidden = false
  @observable navKey = 'key'

  @action navToggler = v => {
    this.isNavOpen = v
  }

  @action setNavKey = v => {
    this.navKey = v
  }

  @action navSmallToggler = v => {
    this.sideBarOpen = v
    //axios.put('/api/common/sidebarState', { value: v })
  }

  @action navSmallTogglerInit = v => {
    this.sideBarOpen = v
  }

  @action setNavHidden = v => {
    this.isNavHidden = v
  }

  @action setMobileHeaderHidden = v => {
    this.isMobileHeaderHidden = v
  }
}

export default NavStore
