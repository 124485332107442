

import axios from 'axios';

// Function to set default Axios configuration for regular API calls
export const setDefaultAxios = () => {
  axios.defaults.headers.get['Pragma'] = 'no-cache';
  axios.defaults.baseURL = process.env.API_URI; // Make sure to define API_URI in your environment
  axios.defaults.headers.common['demo-mode'] = 'false';
  
};

// Function to set default Axios configuration for demo API calls
export const setDemoDefaultAxios = (displayName: string) => {
  // const baseURL = process.env.NODE_ENV === 'production' ? process.env.DEMO_URI : process.env.DEMO_URI_DEV;
  const baseURL = process.env.ENVIRONMENT === 'prod' ? process.env.DEMO_URI : process.env.DEMO_URI_DEV;
  axios.defaults.headers.get['Pragma'] = 'no-cache';
  axios.defaults.baseURL = baseURL; // Make sure to define DEMO_URI and DEMO_URI_DEV in your environment
  axios.defaults.headers.common['demo-mode'] = 'true';
  axios.defaults.headers.common['selected-demo-user'] = displayName;
  // You can add more default settings that are specific to demo mode here
};


