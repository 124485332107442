import { action, observable } from 'mobx'
import * as moment from 'moment'
import axios from 'axios'
import i18n from 'i18next'
import { getKeyOfAllLanguages } from '../helpers/languageHelper'

export default class DateFilterStore {
  @observable currentModule: any
  @observable recognitions: any = {
    dateOption: 'All recognitions',
    startDate: moment().subtract(12, 'month').toDate(),
    dueDate: moment().endOf('day').toDate(),
    quarterOptions: [],
    quarters: []
  }
  // i18n.getDataByLanguage(this.language()).translation['customDates']
  @observable tasks: any = {
    dateOption: 'All tasks',
    startDate: moment().subtract(3, 'month').toDate(),
    dueDate: moment().endOf('day').toDate(),
    quarterOptions: [],
    quarters: []
  }

  @observable feedback: any = {
    dateOption: 'All feedback',
    startDate: moment().subtract(12, 'month').toDate(),
    dueDate: moment().endOf('day').toDate(),
    quarterOptions: [],
    quarters: []
  }

  @observable goals: any = {
    dateOption: 'All goals',
    startDate: moment().startOf('day').toDate(),
    dueDate: moment().add(1, 'month').toDate(),
    quarterOptions: [],
    quarters: []
  }

  @observable meetingGoals: any = {
    dateOption: 'All goals',
    startDate: moment().startOf('day').toDate(),
    dueDate: moment().add(1, 'month').toDate(),
    quarterOptions: [],
    quarters: []
  }

  @observable goalGroups: any = {
    dateOption: 'All goals',
    startDate: moment().startOf('day').toDate(),
    dueDate: moment().add(1, 'month').toDate(),
    quarterOptions: [],
    quarters: []
  }

  @observable userGoals: any = {
    dateOption: 'Active goals',
    startDate: moment().startOf('day').toDate(),
    dueDate: moment().add(1, 'month').toDate(),
    quarterOptions: [],
    quarters: []
  }

  @observable reviews: any = {
    dateOption: 'All reviews',
    startDate: moment().subtract(12, 'month').toDate(),
    dueDate: moment().endOf('day').toDate(),
    quarterOptions: [],
    quarters: []
  }

  @observable feedbackSummarize: any = {
    dateOption: 'All feedback',
    startDate: moment().subtract(3, 'month').toDate(),
    dueDate: moment().endOf('day').toDate(),
    quarterOptions: [],
    quarters: []
  }

  @observable surveys: any = {
    dateOption: 'All surveys',
    startDate: moment().subtract(12, 'month').toDate(),
    dueDate: moment().endOf('day').toDate(),
    quarterOptions: [],
    quarters: []
  }

  @action setCurrentModule(m) {
    this.currentModule = m
  }

  @action changeDateOption(currentModule, dateOption) {
    this[currentModule].dateOption = dateOption
  }

  @action setInitialDateFilter(determinedDates, cb) {
    if (determinedDates.goal !== undefined) {
      this['goals']['startDate'] =
        determinedDates?.goal?.startDate !== undefined && determinedDates?.goal?.startDate !== null
          ? moment(determinedDates.goal.startDate).startOf('day').toDate()
          : undefined

      this['goals']['dueDate'] =
        determinedDates?.goal?.dueDate !== undefined && determinedDates?.goal?.dueDate !== null
          ? moment(determinedDates.goal.dueDate).startOf('day').toDate()
          : undefined

      this['goals']['dateOption'] = determinedDates?.goal?.dateOption || 'All goals'
    }

    if (determinedDates.feedback !== undefined) {
      if (determinedDates.feedback.startDate !== undefined) {
        if (determinedDates.feedback.startDate === null) {
          this['feedback']['startDate'] = undefined
        } else {
          this['feedback']['startDate'] = moment(determinedDates.feedback.startDate).startOf('day').toDate()
        }
      }
      if (determinedDates.feedback.dueDate !== undefined) {
        if (determinedDates.feedback.startDate === null) {
          this['feedback']['dueDate'] = undefined
        } else {
          this['feedback']['dueDate'] = moment(determinedDates.feedback.dueDate).endOf('day').toDate()
        }
      }
      if (determinedDates.feedback.dateOption !== undefined) {
        this['feedback']['dateOption'] = determinedDates.feedback.dateOption || 'All feedback'
      }
    }
    if (determinedDates.review !== undefined) {
      if (determinedDates.review.startDate !== undefined) {
        if (determinedDates.review.startDate === null) {
          this['reviews']['startDate'] = undefined
        } else {
          this['reviews']['startDate'] = moment(determinedDates.review.startDate).startOf('day').toDate()
        }
      }
      if (determinedDates.review.dueDate !== undefined) {
        if (determinedDates.review.startDate === null) {
          this['reviews']['dueDate'] = undefined
        } else {
          this['reviews']['dueDate'] = moment(determinedDates.review.dueDate).endOf('day').toDate()
        }
      }
      if (determinedDates.review.dateOption !== undefined) {
        this['reviews']['dateOption'] = determinedDates.review.dateOption || 'All reviews'
      }
    }
    if (determinedDates.survey !== undefined) {
      if (determinedDates.survey.startDate !== undefined) {
        if (determinedDates.survey.startDate === null) {
          this['surveys']['startDate'] = undefined
        } else {
          this['surveys']['startDate'] = moment(determinedDates.survey.startDate).startOf('day').toDate()
        }
      }
      if (determinedDates.survey.dueDate !== undefined) {
        if (determinedDates.survey.dueDate === null) {
          this['surveys']['dueDate'] = undefined
        } else {
          this['surveys']['dueDate'] = moment(determinedDates.survey.dueDate).endOf('day').toDate()
        }
      }
      if (determinedDates.survey.dateOption !== undefined) {
        this['surveys']['dateOption'] = determinedDates.survey.dateOption || 'All reviews'
      }
    }
    if (determinedDates.task !== undefined) {
      if (determinedDates.task.startDate !== undefined) {
        if (determinedDates.task.startDate === null) {
          this['tasks']['startDate'] = undefined
        } else {
          this['tasks']['startDate'] = moment(determinedDates.task.startDate).startOf('day').toDate()
        }
      }
      if (determinedDates.task.dueDate !== undefined) {
        if (determinedDates.task.startDate === null) {
          this['tasks']['dueDate'] = undefined
        } else {
          this['tasks']['dueDate'] = moment(determinedDates.task.dueDate).endOf('day').toDate()
        }
      }
      if (determinedDates.task.dateOption !== undefined) {
        console.log(determinedDates.task.dateOption)

        this['tasks']['dateOption'] = determinedDates.task.dateOption || 'All tasks'
      }
    }
    if (determinedDates.recognition !== undefined) {
      if (determinedDates.recognition.startDate !== undefined) {
        if (determinedDates.recognition.startDate === null) {
          this['recognitions']['startDate'] = undefined
        } else {
          this['recognitions']['startDate'] = moment(determinedDates.recognition.startDate).startOf('day').toDate()
        }
      }
      if (determinedDates.recognition.dueDate !== undefined) {
        if (determinedDates.recognition.startDate === null) {
          this['recognitions']['dueDate'] = undefined
        } else {
          this['recognitions']['dueDate'] = moment(determinedDates.recognition.dueDate).endOf('day').toDate()
        }
      }
      if (determinedDates.recognition.dateOption !== undefined) {
        this['recognitions']['dateOption'] = determinedDates.recognition.dateOption || 'All recognitions'
      }
    }
  }

  @action setQuarters({ quarterOptions, quarters }: any) {
    this[this.currentModule].quarterOptions = quarterOptions
    this[this.currentModule].quarters = quarters
  }

  @action setQuartersForSummarize({ quarterOptions, quarters }: any) {
    this['feedbackSummarize'].quarterOptions = quarterOptions
    this['feedbackSummarize'].quarters = quarters
  }

  @action setDateOption(dateOption: any, cb) {
    this[this.currentModule].dateOption = dateOption
    axios
      .post('/api/common/updateDeterminedDates', { dateOption: dateOption, currentModule: this.currentModule })
      .then(response => {
        //console.log('Response :', response)
      })
    cb()
  }

  @action setDateOptionWithSpecificModule(dateOption: any, module: any, cb) {
    this[module].dateOption = dateOption
    cb()
  }

  @action setCurrentModuleWithCallBack(m, cb) {
    this.currentModule = m
    cb()
  }

  @action setDateOptionWithoutUpdate(dateOption, cb) {
    this[this.currentModule].dateOption = dateOption
    cb()
  }

  @action setStartAndDueDate(dates: any, cb) {
    this[this.currentModule].startDate = dates.startDate
    this[this.currentModule].dueDate = dates.dueDate
    axios
      .post('/api/common/updateDeterminedDates', {
        startDate: dates.startDate,
        dueDate: dates.dueDate,
        currentModule: this.currentModule
      })
      .then(response => {})
    cb()
  }

  @action setStartAndDueDateWithSpecificModule(dates: any, module: any, cb) {
    this[module].startDate = dates.startDate
    this[module].dueDate = dates.dueDate
    cb()
  }

  @action setStartAndDueDateForUndefinedDatesWithSpecificModule(module: any, cb) {
    this[module].startDate = undefined
    this[module].dueDate = undefined
    cb()
  }

  @action setStartAndDueDateForUndefinedDates(cb) {
    this[this.currentModule].startDate = undefined
    this[this.currentModule].dueDate = undefined
    axios
      .post('/api/common/updateDeterminedDates', {
        startDate: null,
        dueDate: null,
        currentModule: this.currentModule
      })
      .then(response => {})
    cb()
  }

  @action setStartAndDueDateWithoutUpdate(dates: any, cb) {
    this[this.currentModule].startDate = dates.startDate
    this[this.currentModule].dueDate = dates.dueDate
    cb()
  }

  @action setDateWithName(whichDate, date, cb) {
    if (whichDate === 'startDate') {
      date = moment(date).startOf('day').toDate()
    }
    if (whichDate === 'dueDate') {
      date = moment(date).endOf('day').toDate()
    }
    this[this.currentModule][whichDate] = date
    if (whichDate === 'startDate') {
      axios
        .post('/api/common/updateDeterminedDates', { startDate: date, currentModule: this.currentModule })
        .then(response => {
          console.log('Response :', response)
        })
    }
    if (whichDate === 'dueDate') {
      axios
        .post('/api/common/updateDeterminedDates', { dueDate: date, currentModule: this.currentModule })
        .then(response => {
          console.log('Response :', response)
        })
    }
    cb()
  }

  @action setDates(startDate, dueDate, cb) {
    startDate = moment(startDate).startOf('day').toDate()
    dueDate = moment(dueDate).endOf('day').toDate()
    this[this.currentModule]['startDate'] = startDate
    this[this.currentModule]['dueDate'] = dueDate
    cb()
  }

  @action setDateWithNameSpecificModule(whichDate, date, module, cb) {
    if (whichDate === 'startDate') {
      date = moment(date).startOf('day').toDate()
    }
    if (whichDate === 'dueDate') {
      date = moment(date).endOf('day').toDate()
    }
    this[module][whichDate] = date
    cb()
  }
}
