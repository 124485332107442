import { action, computed, observable, toJS } from 'mobx'
import { getProfilePic, getProfilePicLarge } from '../helpers/GraphHelpers'
import axios from 'axios'
import { forIn } from 'lodash'
import * as mom from 'moment'
const moment = require('moment').default || require('moment')
import { EncryptStorage } from 'encrypt-storage'
import { cachePrefix, storageKey } from '../AppInner'
import Cookies from 'universal-cookie'
import { responsibleTypeStringToScopeArrayPropertyObj } from '../constants/userConstants'
import { setLanguage } from '../helpers/languageHelper'
const Sentry = require('@sentry/browser')

const cookies = new Cookies()

export default class UserStore {
  @observable isUserSet: Boolean = false
  @observable platform: String = ''
  @observable accessToken: String = ''
  @observable displayName: String = ''
  @observable givenName: String = ''
  @observable jobTitle: String = ''
  @observable profilePic: any = ''
  @observable createdAt: any = ''
  @observable graphAccessToken: String = ''
  @observable graphRefreshToken: String = ''
  @observable graphTokenExpires: Date = null
  @observable exchangeToken: String = ''
  @observable oid: String = ''
  @observable tenantId: String = ''
  @observable inOffice: Boolean = false
  @observable idToken: String = ''
  @observable isAdmin: Boolean = false
  @observable UPN: String = ''
  @observable mail: String = ''
  @observable mrus: Array<any> = []
  @observable groups: any = null
  @observable departments: any = null
  @observable isGoalDepartmentsSet: Boolean = false
  @observable groupsSet: Boolean = false
  @observable manager: any = null
  @observable pendingManagers: any = []
  @observable directReports: any = []
  @observable focusPeople: any = []
  @observable otherPeople: any = []
  @observable focusPeopleRequests: any = []
  @observable tenant: any = null
  @observable tenantSettings: any = null
  @observable userSettings: any = {}
  @observable taskSubscription: any = null
  @observable disabledNotifications: any = {}
  @observable isManager: Boolean = false
  @observable extendedDirectReports: any = []
  @observable allFocusPeopleSet: Boolean = false
  @observable allCompanyResourcesSet: Boolean = false
  @observable redirectedFromAdaptive: Boolean = false
  @observable isMobile: Boolean = false
  @observable activityRemindDate: Date = null
  @observable hasDismissedBanner: Boolean = null
  @observable hasDismissedTaskBanner: Boolean = false
  @observable hasSeenOnboardingModal: Boolean = false
  @observable hasAzureRelation: Boolean = false
  @observable determinedDates: any = {}
  @observable hasDismissedGoalBanner: Boolean = false
  @observable otherPics = []
  @observable department = ''
  @observable role = 0
  @observable impersonateMode = false
  @observable demoMode = false
  @observable selectedDemoUser = null
  @observable impersonateOldUser = null
  @observable hasImpersonationRights = false
  @observable teamsLogin: Boolean = false
  @observable hasAdminConsent = false
  @observable onboardingChoices = []
  @observable companyResources = []
  @observable printReviewIds = []
  @observable country: String = ''
  @observable birthday = ''
  @observable employeeHireDate = ''
  @observable officeLocation = ''
  @observable responsibleType = ''
  @observable responsibleDepartments = []
  @observable responsibleOfficeLocations = []
  @observable responsibleCountries = []
  @observable goalTypes = []
  @observable hasManagerialAccess = false
  @observable focusPeopleUserRelations: any = null
  @observable showGetStartedPage = false
  @observable hasSeenGetStartedPage: Boolean = false
  @observable preferredLanguage = false

  @computed get allFocus() {
    let allFocus = []
    // if (this.manager.length > 0) {
    //   allFocus = allFocus.concat(toJS(this.manager))
    // }
    if (this.manager) {
      allFocus.push(this.manager)
    }
    if (this.directReports) {
      allFocus = allFocus.concat(toJS(this.directReports))
    }
    if (this.focusPeople) {
      allFocus = allFocus.concat(toJS(this.focusPeople))
    }
    if (this.pendingManagers) {
      allFocus = allFocus.concat(toJS(this.pendingManagers))
    }
    return allFocus
  }

  @action setWidth(width) {
    if (width < 600) {
      this.isMobile = true
    } else {
      if (this.isMobile === true) {
        this.isMobile = false
      }
    }
  }

  @action setNewToken(graphAccessToken, graphTokenExpires) {
    this.graphAccessToken = graphAccessToken
    this.graphTokenExpires = graphTokenExpires
  }

  // @computed get useInTeamsPopupShow(){
  //   return !this.teamsLogin && !this.userSettings.dontShowAgainUseInTeams
  // }

  @action setUser(user, platform) {
    this.displayName = user.displayName
    this.givenName = user.givenName
    this.jobTitle = user.jobTitle
    this.oid = user.oid
    this.department = user.department
    this.country = user.country
    this.birthday = user.birthday
    this.employeeHireDate = user.employeeHireDate
    this.officeLocation = user.officeLocation
    this.graphAccessToken = user.graphAccessToken
    this.graphRefreshToken = user.graphRefreshToken
    this.graphTokenExpires = user.graphTokenExpires
    this.isUserSet = true
    this.UPN = user.UPN
    this.mail = user.mail
    this.tenant = user.tenant
    this.tenantId = user.tenantId
    this.tenantSettings = user.tenant.settings
    this.userSettings = user.userSettings
    this.role = user.role
    this.responsibleType = user.responsibleType
    this.responsibleDepartments = user.responsibleDepartments
    this.responsibleOfficeLocations = user.responsibleOfficeLocations
    this.responsibleCountries = user.responsibleCountries
    this.teamsLogin = user.teamsLogin
    this.onboardingChoices = user.tenant.onboardingInfo?.onboardingChoices || []
    this.preferredLanguage = user.userSettings?.selectedLanguage || user.tenant.settings?.selectedLanguage || 'en'

    setLanguage(user.userSettings?.selectedLanguage || user.tenant.settings?.selectedLanguage || 'en')

    if (user.taskSubscription) {
      this.taskSubscription = user.taskSubscription
    }

    let defaultDisabledNotifications = {}
    this.disabledNotifications = { ...defaultDisabledNotifications, ...user.disabledNotifications }

    this.createdAt = user.createdAt
    this.platform = platform
    if (user.role === 2 || user.role === 4) {
      this.isAdmin = true
    }

    if (user.demoMode) {
      // empty direct reports, focus people and manager, so that the user can't see any data
      this.directReports = []
      this.focusPeople = []
      this.mrus = []
      this.manager = null
      this.pendingManagers = []
      this.otherPeople = []
      this.focusPeopleRequests = []
      // this.profilePic = ''
      // this.otherPics = []

      localStorage.setItem('demoMode', 'true')
      localStorage.setItem('selectedDemoUser', user.displayName)
    } else {
      // set local storage data demo mode to false
      // this.profilePic = ''
      // this.otherPics = []

      localStorage.setItem('demoMode', 'false')
      localStorage.removeItem('selectedDemoUser')
    }

    this.loadMrus()
    if (!this.tenantSettings.disableGroupGoals) {
      this.loadGroups()
    }
    this.loadFocus()

    getProfilePicLarge(user.graphAccessToken, user.UPN)
      .then(value => {
        this.profilePic = value
      })
      .catch(err => console.log(err))

    this.getCompanyResources()

    this.activityRemindDate = user.activityRemindDate
    this.hasDismissedBanner = user.hasDismissedBanner
    this.hasDismissedTaskBanner = user.hasDismissedTaskBanner
    this.hasSeenOnboardingModal = user.hasSeenOnboardingModal
    this.showGetStartedPage = user.showGetStartedPage
    this.hasSeenGetStartedPage = user.hasSeenGetStartedPage
    this.hasDismissedGoalBanner = user.hasDismissedGoalBanner
    this.isManager = user.isManager
    this.determinedDates = user.determinedDates
    this.impersonateMode = user.impersonateMode
    this.demoMode = user.demoMode
    this.selectedDemoUser = user.selectedDemoUser
    this.impersonateOldUser = user.impersonateOldUser
    this.hasImpersonationRights = user.hasImpersonationRights
    this.hasAdminConsent = user.hasAdminConsent
    if (moment().diff(moment(user.createdAt), 'hour', true) < 1) {
      //@ts-ignore
      if (window.clarity) {
        //@ts-ignore
        window.clarity('set', 'usertype', 'newuser')
      }
    }

    if (moment().diff(moment(user.tenant.createdAt), 'hour', true) < 1) {
      //@ts-ignore
      if (window.clarity) {
        //@ts-ignore
        window.clarity('set', 'usertype', 'newtenant')
      }
    }
  }

  @action getCompanyResources() {
    this.allCompanyResourcesSet = false
    axios
      .get('/api/common/getCompanyResources')
      .then(res => {
        this.companyResources = res.data
        this.allCompanyResourcesSet = true
      })
      .catch(err => console.log(err))
  }

  @action setOnboardingChoices = choices => {
    this.onboardingChoices = choices
  }

  @action setIdToken(token) {
    this.idToken = token
  }

  @action setHasSeenOnboardingModal(value) {
    this.hasSeenOnboardingModal = value
    const localStorageData = this.getLocalStorageData()
    if (localStorageData) {
      localStorageData.userData.hasSeenOnboardingModal = value
      this.setLocalStorageData(localStorageData)
    }
  }

  @action setShowGetStartedPage(value) {
    this.showGetStartedPage = value
    const localStorageData = this.getLocalStorageData()
    if (localStorageData) {
      localStorageData.userData.showGetStartedPage = value
      this.setLocalStorageData(localStorageData)
    }
  }

  @action setHasSeenGetStartedPage(value) {
    this.hasSeenGetStartedPage = value
    const localStorageData = this.getLocalStorageData()
    if (localStorageData) {
      localStorageData.userData.hasSeenGetStartedPage = value
      this.setLocalStorageData(localStorageData)
    }
  }

  @action setHasDismissedBanner(value) {
    this.hasDismissedBanner = value
  }

  @action setHasDismissedGoalBanner(v) {
    this.hasDismissedGoalBanner = v
  }

  @action setRedirectedFromAdaptive() {
    this.redirectedFromAdaptive = true
  }

  @action unSetRedirectedFromAdaptive() {
    if (this.platform === 'teams') {
      this.redirectedFromAdaptive = false
    }
  }

  @action setUserWeb(user) {
    this.displayName = user.displayName
    this.jobTitle = user.jobTitle
    this.graphAccessToken = user.graphAccessToken
    this.graphRefreshToken = user.graphRefreshToken
    this.oid = user.oid
    this.tenantId = user.tenantId
    this.isUserSet = true
    this.UPN = user.UPN
    this.tenantSettings = user.tenantSettings
    this.role = user.role
    if (user.role === 2) {
      this.isAdmin = true
    }
    this.loadMrus()
    this.loadFocus()
  }
  @action setExchangeToken(token) {
    this.exchangeToken = token
    this.inOffice = true
  }
  @action setAccessToken(token) {
    this.graphAccessToken = token
  }
  @action setDirectReports(directReports) {
    this.directReports = directReports
  }
  @action setFocusPeople(directReports) {
    this.focusPeople = directReports
  }
  @action setManager(directReports) {
    this.manager = directReports
  }
  @action setDigestSetting(digestOn) {
    let settings = this.userSettings
    if (settings) {
      settings.digestOn = digestOn
    } else {
      settings = { digestOn: digestOn }
    }
    this.userSettings = settings
  }
  @action setNotificationSetting(key, value) {
    let settingsObject = {}
    switch (key) {
      case 'f':
        settingsObject = {
          f: value,
          f1: value,
          f2: value,
          f3: value,
          f4: value,
          f5: value,
          f6: value,
          f7: value,
          f8: value,
          f9: value,
          f10: value,
          f11: value
        }
        break
      case 't':
        settingsObject = {
          t: value,
          t1: value,
          t2: value,
          t3: value,
          t4: value,
          t5: value,
          t6: value,
          t7: value
        }
        break
      case 'r':
        settingsObject = {
          r: value,
          r1: value,
          r2: value
        }
        break
      case 'l':
        settingsObject = {
          l: value,
          l1: value,
          l2: value
        }
        break
      case 'u':
        settingsObject = {
          u: value,
          u1: value,
          u2: value,
          u3: value,
          u4: value,
          u5: value,
          u6: value
        }
        break
      case 'g':
        settingsObject = {
          g: value,
          g1: value,
          g2: value,
          g3: value,
          g4: value,
          g6: value,
          g7: value
        }
        break
      case 'v':
        settingsObject = {
          v: value,
          v1: value,
          v2: value,
          v3: value,
          v4: value,
          v5: value,
          v6: value,
          v7: value,
          v8: value
        }
        break
      case 's':
        settingsObject = {
          s: value,
          s1: value,
          s2: value,
          s3: value
        }
        break
      case 't2':
        settingsObject = {
          t2: value,
          t4: value
        }
      case 'v1':
        settingsObject = {
          v1: value,
          v2: value,
          v3: value
        }
      case 'v6':
        settingsObject = {
          v6: value,
          v7: value,
          v8: value
        }
      case 'f1':
        settingsObject = {
          f1: value,
          f3: value,
          f10: value
        }
      case 'f2':
        settingsObject = {
          f2: value,
          f4: value,
          f11: value
        }
      case 'f5':
        settingsObject = {
          f5: value,
          f6: value
        }
      case 'f7':
        settingsObject = {
          f7: value,
          f8: value,
          f9: value
        }
      default:
        settingsObject[key] = value
        break
    }
    let settings = toJS(this.disabledNotifications)

    if (!settings) {
      settings = {}
    }
    forIn(settingsObject, (v, k) => {
      settings[k] = v
    })

    this.disabledNotifications = settings
    return settingsObject
  }

  @action setNotificationSettingsAll(settingsObject) {
    let settings = toJS(this.disabledNotifications)
    if (!settings) {
      settings = {}
    }
    forIn(settingsObject, (v, k) => {
      settings[k] = v
    })
    this.disabledNotifications = settingsObject
    return settingsObject
  }

  @action setOneReminderSetting(digestOn) {
    let settings = this.userSettings
    if (settings) {
      settings.oneReminder = digestOn
    } else {
      settings = { oneReminder: digestOn }
    }
    this.userSettings = settings
  }
  @action setOneReminderPeriodSetting(value) {
    let settings = this.userSettings
    if (settings) {
      settings.oneReminderPeriod = value
    } else {
      settings = { oneReminderPeriod: value }
    }
    this.userSettings = settings
  }
  @action setSelectedLanguageSetting(value) {
    let settings = this.userSettings
    if (settings) {
      settings.selectedLanguage = value
    } else {
      settings = { selectedLanguage: value }
    }
    const localStorageData = this.getLocalStorageData()
    if (localStorageData) {
      localStorageData.userData.userSettings = settings
      this.setLocalStorageData(localStorageData)
    }
    this.userSettings = settings
  }
  @action setEmailFrequency(frequency) {
    let settings = this.userSettings
    if (settings) {
      settings.emailFrequency = frequency
    } else {
      settings = { emailFrequency: frequency }
    }

    this.userSettings = settings
  }

  @action setNotificationType(type) {
    let settings = this.userSettings
    if (settings) {
      settings.notificationType = type
    } else {
      settings = { notificationType: type }
    }
    this.userSettings = settings
  }
  @action setOneSuggestionSetting(x) {
    let settings = this.userSettings
    if (settings) {
      settings.oneSuggestionOn = x
    } else {
      settings = { oneSuggestionOn: x }
    }

    this.userSettings = settings
  }
  @action setDontShowAgainUseInTeams(x) {
    let settings = this.userSettings
    if (settings) {
      settings.dontShowAgainUseInTeams = x
    } else {
      settings = { dontShowAgainUseInTeams: x }
    }

    this.userSettings = settings
  }

  getLocalStorageData() {
    const key = this.platform === 'teams' ? this.UPN : this.idToken
    const encryptStorage = new EncryptStorage(key.toString(), { prefix: cachePrefix })

    try {
      const localStorageData = encryptStorage?.getItem(storageKey)
      return localStorageData
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          key,
          platform: this.platform,
          upn: this.UPN,
          idToken: this.idToken,
          storageKey,
          encryptedData: encryptStorage?.getItem(storageKey, true)
        }
      })
    }

    return null
  }

  setLocalStorageData(localStorageData) {
    const key = this.platform === 'teams' ? this.UPN : this.idToken

    const encryptStorage = new EncryptStorage(key.toString(), {
      prefix: cachePrefix
    })
    encryptStorage.setItem(storageKey, localStorageData)
  }

  @action setOnboardingCardDismissed(x) {
    let settings = this.userSettings
    if (settings) {
      settings.onboardingCardDismissed = x
    } else {
      settings = { onboardingCardDismissed: x }
    }
    const localStorageData = this.getLocalStorageData()
    if (localStorageData) {
      localStorageData.userData.userSettings = settings
      this.setLocalStorageData(localStorageData)
    }
    this.userSettings = settings
  }

  @action setDirectReportFrequency(frequency) {
    let settings = this.userSettings
    if (settings) {
      settings.directReportFrequency = frequency
    } else {
      settings = { directReportFrequency: frequency }
    }

    this.userSettings = settings
  }
  @action setFirstDayOfWeek(day) {
    let settings = this.userSettings
    if (settings) {
      settings.firstDayOfWeek = day
    } else {
      settings = { firstDayOfWeek: day }
    }

    this.userSettings = settings
  }

  @action setTimeFormat(timeFormat) {
    let settings = this.userSettings
    if (settings) {
      settings.timeFormat = timeFormat
    } else {
      settings = { timeFormat: timeFormat }
    }

    this.userSettings = settings
  }

  @action setTenant(tenant) {
    this.tenant = tenant
  }

  @action setTaskSubscription(value) {
    this.taskSubscription = value
  }

  @action setPresenceOn(value) {
    this.userSettings.presenceOn = value
  }

  @action updateRelations(user, newRelations, relationType) {
    if (relationType === 'manager') {
      this.manager.userRelation = newRelations
    } else if (relationType === 'directReport') {
      let directReportIndex = this.directReports.findIndex(directReport => {
        return directReport.userPrincipalName === user.userPrincipalName
      })
      this.directReports[directReportIndex].userRelation = newRelations
    } else if (relationType === 'focus') {
      let directReportIndex = this.focusPeople.findIndex(directReport => {
        return directReport.userPrincipalName === user.userPrincipalName
      })
      this.focusPeople[directReportIndex].userRelation = newRelations
    }
  }

  @action removeFocusPerson(oid) {
    let relatedUser = this.allFocus.find(fp => {
      return fp.id === oid || fp.oid === oid
    })

    if (relatedUser?.focusType) {
      let body = {
        oid: oid,
        focusType: relatedUser.focusType
      }
      axios
        .post('/api/common/removeFocusPerson', body)
        .then(response => {})
        .catch(error => {
          console.log('error ' + error)
        })
    } else {
      let body = {
        relatedUser: relatedUser
      }
      axios
        .post('/api/common/removeAzurePerson', body)
        .then(response => {})
        .catch(error => {
          console.log('error ' + error)
        })
    }

    let focusPeople = toJS(this.focusPeople)
    let index = focusPeople.findIndex(fp => fp.id === oid || fp.oid === oid)
    if (index > -1) {
      focusPeople.splice(index, 1)
      this.setFocusPeople(focusPeople)
    } else {
      let directReports = toJS(this.directReports)
      index = directReports.findIndex(person => person.id === oid || person.oid === oid)
      if (index > -1) {
        directReports.splice(index, 1)
        this.setDirectReports(directReports)
      } else if ((oid = this.manager.oid)) {
        this.setManager(null)
      }
    }
  }

  @action getFocusPeopleRequests(upn) {
    axios
      .get('/api/common/getFocusPeopleRequests')
      .then(res => (this.focusPeopleRequests = res.data))
      .catch(err => console.log(err))
  }

  @action getProfilePic(upn) {
    const otherPic = this.otherPics.find(o => o.upn === upn)
    if (otherPic) {
      return otherPic.pic
    }
    let user = this.mrus.find(mru => mru.userPrincipalName === upn)
    if (!user) user = this.directReports.find(dr => dr.userPrincipalName === upn)
    if (!user) user = this.focusPeople.find(fp => fp.userPrincipalName === upn)
    if (upn === this.UPN) user = { profilePic: this.profilePic }
    if (user && user.profilePic !== '') return user.profilePic
    return null
  }

  @action addPic(upn, pic) {
    this.otherPics.push({ upn, pic })
  }

  @action changeSideBarSetting(v) {
    this.userSettings.sideBarOpen = v
  }

  @action changeShowBirthdayOrAnniversary(v) {
    this.userSettings.disableRecognitionBirthdayOrAnniversaryFilter = !v
  }

  loadGroups() {
    axios
      .get('/api/common/getMyGroups')
      .then(response => {
        let groups = response.data
        groups.sort(function (a, b) {
          var keyA = new Date(a.lastCreatedAt),
            keyB = new Date(b.lastCreatedAt)
          // Compare the 2 dates
          if (keyA < keyB) return -1
          if (keyA > keyB) return 1
          return 0
        })
        this.groups = groups
        this.groupsSet = true
      })
      .catch(error => {
        this.groupsSet = true
        //console.log('error ' + error);
      })
  }

  @action setGoalTypes(goalTypes) {
    this.goalTypes = goalTypes
  }

  loadMrus() {
    axios
      .get('/api/common/getMRUs')
      .then(response => {
        let returnedValues = response.data
        let filteredPersonas = returnedValues.map((persona, index) => {
          return {
            id: persona.id,
            key: index,
            profilePic: '',
            text: persona.displayName,
            department: persona.department,
            country: persona.country,
            birthday: persona.birthday,
            employeeHireDate: persona.employeeHireDate,
            officeLocation: persona.officeLocation,
            secondaryText: persona.jobTitle,
            userPrincipalName: persona.userPrincipalName,
            userRelation: {
              hasNotSignedUp: persona.hasNotSignedUp
            },
            mail: persona.userPrincipalName,
            displayName: persona.displayName,
            isNotAllowed: persona.isNotAllowed
          }
        })
        this.mrus = filteredPersonas
        filteredPersonas.forEach(async (persona, i) => {
          this.mrus[i].profilePic = await getProfilePic(this.graphAccessToken, persona.userPrincipalName)
        })
      })
      .catch(error => {
        //console.log('error ' + error);
      })
  }

  loadFocus() {
    this.allFocusPeopleSet = false
    this.loadUserRelations()

    axios
      .get('/api/common/getMyFocusPeople2')
      .then(async response => {
        if (response.data) {
          this.directReports = response.data.directReports
          this.manager = response.data.manager
          this.pendingManagers = response.data.myPendingManagers
          if (response.data.focusPeople) {
            this.focusPeople = response.data.focusPeople
          }
          if (response.data.otherPeople) {
            this.otherPeople = response.data.otherPeople
          }
          if (this.directReports && this.directReports.length > 0) {
            this.isManager = true
          }
          const hasManagerialAccess = this.focusPeople.some(fp => fp.isDirectRelation && fp.status == 2)
          if (hasManagerialAccess) {
            this.hasManagerialAccess = true
          }
          let allFocus = []
          let extendedDirectReports = []
          if (response.data.pendingManagers && response.data.pendingManagers.length > 0) {
            extendedDirectReports = extendedDirectReports.concat(response.data.pendingManagers)
          }
          if (
            response.data.myPendingDirectReportsForManager &&
            response.data.myPendingDirectReportsForManager.length > 0
          ) {
            extendedDirectReports = extendedDirectReports.concat(response.data.myPendingDirectReportsForManager)
          }
          if (response.data.directReports) {
            allFocus = allFocus.concat(response.data.directReports)
            extendedDirectReports = extendedDirectReports.concat(response.data.directReports)
          }
          if (response.data.focusPeople) {
            allFocus = allFocus.concat(response.data.focusPeople)
            let indirectReports = response.data.focusPeople.filter(fp => fp.isDirectRelation && fp.status == 2)
            if (indirectReports.length > 0) {
              extendedDirectReports = extendedDirectReports.concat(indirectReports)
            }
          }
          let hasAzureRelation = allFocus.filter(user => {
            return !('focusType' in user)
          })
          if (hasAzureRelation.length > 0) {
            this.hasAzureRelation = true
          }

          this.extendedDirectReports = extendedDirectReports
          this.allFocusPeopleSet = true
          this.loadRelatedPhotos()
          if (this.focusPeopleUserRelations) {
            this.setUserRelationsToFocusPeople()
          }
          //this.allFocus = response.data.directReports.concat([response.data.manager]).concat(response.data.focusPeople);
        }
      })
      .catch(error => {
        //console.log('error ' + error);
      })
  }

  loadUserRelations() {
    axios
      .get('/api/common/getFocusPeopleUserRelations')
      .then(response => {
        this.focusPeopleUserRelations = response.data
        if (this.allFocusPeopleSet) {
          this.setUserRelationsToFocusPeople()
        }
      })
      .catch(error => {
        //console.log('error ' + error);
      })
  }

  setUserRelationsToFocusPeople() {
    const { directReports, focusPeople, otherPeople, pendingManagers, manager } = this.focusPeopleUserRelations
    this.directReports = this.directReports.map(persona => {
      let dr = directReports.find(dr => dr.oid === persona.oid || dr.oid === persona.id)
      if (dr) {
        persona.userRelation = dr.userRelation
      }
      return persona
    })
    this.focusPeople = this.focusPeople.map(persona => {
      let fp = focusPeople.find(fp => fp.oid === persona.oid || fp.oid === persona.id)
      if (fp) {
        persona.userRelation = fp.userRelation
      }
      return persona
    })

    this.extendedDirectReports = this.extendedDirectReports.map(persona => {
      let edr = focusPeople.find(dr => dr.oid === persona.oid || dr.oid === persona.id)
      if (edr) {
        persona.userRelation = edr.userRelation
      }
      return persona
    })

    if (manager) {
      this.manager.userRelation = manager.userRelation
    }

    this.otherPeople = this.otherPeople.map(persona => {
      let op = otherPeople.find(op => op.oid === persona.oid || op.oid === persona.id)
      if (op) {
        persona.userRelation = op.userRelation
      }
      return persona
    })
    this.pendingManagers = this.pendingManagers.map(persona => {
      let pm = pendingManagers.find(pm => pm.oid === persona.oid || pm.oid === persona.id)
      if (pm) {
        persona.userRelation = pm.userRelation
      }
      return persona
    })
  }

  calculateRelations(persona, relation) {
    let tenantSettings = this.tenantSettings
    let userRelation = persona.userRelation

    let lateRequestFromOwnerCount,
      lateRequestToOwnerCount,
      readRate,
      weekDiff,
      unreadMessageCount,
      lateRequestCount,
      lastOneOnOneMeetingDate,
      lastOneOnOneMeetingDateFromNow,
      hasOneOnOneWarning,
      hasRecognitionFromMeWarning,
      hasRecognitionFromOthersWarning,
      hasFeedbackFromMeWarning,
      hasFeedbackFromOthersWarning
    if (userRelation) {
      lateRequestCount = 0

      unreadMessageCount = userRelation.unreadMessageCount ? userRelation.unreadMessageCount : 0
      let allMessageCount = userRelation.allMessageCount ? userRelation.allMessageCount : 0
      lateRequestFromOwnerCount = userRelation.lateRequestFromOwnerCount ? userRelation.lateRequestFromOwnerCount : 0
      lateRequestToOwnerCount = userRelation.lateRequestToOwnerCount ? userRelation.lateRequestToOwnerCount : 0

      lateRequestCount = lateRequestFromOwnerCount + lateRequestToOwnerCount

      readRate =
        allMessageCount !== 0 ? Math.round(((allMessageCount - unreadMessageCount) / allMessageCount) * 100) : 0
      userRelation.hasReadRateWarning = allMessageCount > 0 && readRate < 90 ? true : false

      if (userRelation.lastOneOnOneMeetingDate) {
        lastOneOnOneMeetingDateFromNow = moment(userRelation.lastOneOnOneMeetingDate).fromNow()
        lastOneOnOneMeetingDate = moment(userRelation.lastOneOnOneMeetingDate).format('ll')
        weekDiff = Math.floor(moment().diff(moment(userRelation.lastOneOnOneMeetingDate), 'weeks'))
        userRelation.hasOneOnOneWarning = weekDiff > tenantSettings.oneOnOneFrequency ? true : false

        if (
          moment(userRelation.lastOneOnOneMeetingDate).diff(moment(), 'days') < 8 &&
          moment(userRelation.lastOneOnOneMeetingDate).diff(moment(), 'days') >= 0
        ) {
          userRelation.hasUpcoming1on1 = true
        }
      } else {
        userRelation.hasOneOnOneWarning = true
      }

      if (relation === 'directReport') {
        userRelation.hasRecognitionFromMeWarning =
          userRelation.lastRecognitionDate &&
          Math.floor(moment().diff(moment(userRelation.lastRecognitionDate), 'weeks')) >
            tenantSettings.recognitionCycleTime
            ? true
            : false

        userRelation.hasRecognitionFromOthersWarning =
          userRelation.lastRecognitionFromOthersDate &&
          Math.floor(moment().diff(moment(userRelation.lastRecognitionFromOthersDate), 'weeks')) > 8
            ? true
            : false

        userRelation.hasFeedbackFromMeWarning =
          userRelation.lastFeedbackDate &&
          Math.floor(moment().diff(moment(userRelation.lastFeedbackDate), 'weeks')) > tenantSettings.feedbackCycleTime
            ? true
            : false

        userRelation.hasFeedbackFromOthersWarning =
          userRelation.lastFeedbackFromOthersDate &&
          Math.floor(moment().diff(moment(userRelation.lastFeedbackFromOthersDate), 'weeks')) > 8
            ? true
            : false
      }

      userRelation.hasRecentRecognition =
        userRelation.lastRecognitionFromOthersDate &&
        moment().diff(moment(userRelation.lastRecognitionFromOthersDate), 'days') < 7
          ? true
          : false
      userRelation.hasRecentFeedback =
        userRelation.lastFeedbackFromOthersDate &&
        moment().diff(moment(userRelation.lastFeedbackFromOthersDate), 'days') < 7 &&
        relation === 'directReport'
          ? true
          : false
    }
    return userRelation
  }

  loadRelatedPhotos() {
    for (let index = 0; index < this.directReports.length; index++) {
      getProfilePic(this.graphAccessToken, this.directReports[index].userPrincipalName).then(val => {
        if (this.directReports[index]) {
          this.directReports[index].profilePic = val
        }
      })
    }
    for (let index = 0; index < this.focusPeople.length; index++) {
      getProfilePic(this.graphAccessToken, this.focusPeople[index].userPrincipalName).then(val => {
        if (this.focusPeople[index]) {
          this.focusPeople[index].profilePic = val
        }
      })
    }
    for (let index = 0; index < this.otherPeople.length; index++) {
      getProfilePic(this.graphAccessToken, this.otherPeople[index].userPrincipalName).then(val => {
        if (this.otherPeople[index]) {
          this.otherPeople[index].profilePic = val
        }
      })
    }
    getProfilePic(this.graphAccessToken, this.manager.userPrincipalName).then(val => {
      this.manager.profilePic = val
    })

    // get pending manager photos
    for (let index = 0; index < this.pendingManagers.length; index++) {
      getProfilePic(this.graphAccessToken, this.pendingManagers[index].userPrincipalName).then(val => {
        if (this.pendingManagers[index]) {
          this.pendingManagers[index].profilePic = val
        }
      })
    }
  }

  @action setUserProfilePic(upn) {
    getProfilePic(this.graphAccessToken, upn).then(val => {
      this.profilePic = val
    })
  }

  @action makeManager() {
    this.isManager = true
  }
  @action setManagerToFalse() {
    this.isManager = false
    //this.manager = null
  }

  @action setDoesntHaveManager(bool) {
    this.userSettings.doesntHaveManager = bool
  }

  @action setDismissTaskBanner(bool) {
    this.hasDismissedTaskBanner = bool
  }

  @action findByUPNAndRemoveOtherPeople(upn) {
    let index = this.otherPeople.findIndex(person => {
      return person.userPrincipalName === upn
    })
    this.otherPeople.splice(index, 1)
  }

  @action setPrintReviewIds(ids) {
    this.printReviewIds = ids
  }

  @action async updateOneOnOneMeetingFilter(value, cb) {
    if (this.determinedDates == undefined) {
      this.determinedDates = {}
    }
    if (this.determinedDates['meeting'] == undefined) {
      this.determinedDates['meeting'] = {}
    }
    this.determinedDates['meeting'].filterOneOnOne = value
    await axios.post('/api/common/updateDeterminedDates', {
      filterOneOnOne: value,
      currentModule: 'meeting'
    })
  }

  @action async updateMeetingViewMode(value, cb) {
    if (this.determinedDates == undefined) {
      this.determinedDates = {}
    }
    if (this.determinedDates['meeting'] === undefined) {
      this.determinedDates['meeting'] = {}
    }
    this.determinedDates['meeting'].viewMode = value
    await axios.post('/api/common/updateDeterminedDates', {
      viewMode: value,
      currentModule: 'meeting'
    })
  }

  @action setTestUser(username) {
    // read an object from a json file /${username}.json
    let jsonInfo = require(`../__test__/__mocks__/users/${username}.json`)

    const user = jsonInfo.user

    const platform = 'web'
    this.displayName = user.displayName
    this.givenName = user.givenName
    this.jobTitle = user.jobTitle
    this.oid = user.oid
    this.department = user.department
    this.graphAccessToken = user.graphAccessToken
    this.graphRefreshToken = user.graphRefreshToken
    // this.graphTokenExpires = user.graphTokenExpires
    this.isUserSet = true
    this.UPN = user.UPN
    this.mail = user.mail
    this.tenant = user.tenant
    this.tenantId = user.tenantId
    this.tenantSettings = user.tenant.settings
    this.userSettings = user.userSettings
    this.role = user.role
    this.teamsLogin = user.teamsLogin
    this.onboardingChoices = user.tenant.onboardingInfo?.onboardingChoices || []

    let defaultDisabledNotifications = {}
    this.disabledNotifications = { ...defaultDisabledNotifications, ...user.disabledNotifications }

    this.createdAt = user.createdAt
    this.platform = platform
    if (user.role === 2 || user.role === 4) {
      this.isAdmin = true
    }

    // Load MRUs
    let returnedValues = jsonInfo.MRUs
    let filteredPersonas = returnedValues.map((persona, index) => {
      return {
        id: persona.id,
        key: index,
        profilePic: '',
        text: persona.displayName,
        secondaryText: persona.jobTitle,
        userPrincipalName: persona.userPrincipalName,
        userRelation: {
          hasNotSignedUp: persona.hasNotSignedUp
        },
        mail: persona.userPrincipalName,
        displayName: persona.displayName,
        isNotAllowed: persona.isNotAllowed
      }
    })
    this.mrus = filteredPersonas
    filteredPersonas.forEach(async (persona, i) => {
      this.mrus[i].profilePic = null
    })

    if (!this.tenantSettings.disableGroupGoals) {
      // Load Groups
      let groups = jsonInfo.groups
      groups.sort(function (a, b) {
        var keyA = new Date(a.lastCreatedAt),
          keyB = new Date(b.lastCreatedAt)
        // Compare the 2 dates
        if (keyA < keyB) return -1
        if (keyA > keyB) return 1
        return 0
      })
      this.groups = groups
      this.groupsSet = true
    }
    if (!this.tenantSettings.disableDepartmentGoals) {
      // Load Departments
      let data = jsonInfo.departments
      const firstItemIndex = data.findIndex(({ _id }) => _id === this.department)
      let firstItem
      if (firstItemIndex > -1) {
        ;[firstItem] = data.splice(firstItemIndex, 1)
      }
      if (firstItem) {
        firstItem.name = `My Department (${firstItem._id})`
      } else {
        firstItem = {}
        firstItem.name = this.department ? `My Department (${this.department})` : 'My Department'
        firstItem._id = this.department ? this.department : 'No department'
        firstItem.count = 0
      }
      data = data.map(obj => ({ ...obj, name: obj._id }))
      const x = data.sort((a, b) => {
        return a._id.localeCompare(b._id)
      })
      this.departments = [firstItem, ...x]
      this.isGoalDepartmentsSet = true
    }
    // Load Focus
    this.allFocusPeopleSet = false
    const focusData = jsonInfo.focus
    if (focusData) {
      this.directReports = focusData.directReports
      this.manager = focusData.manager
      if (focusData.focusPeople) {
        this.focusPeople = focusData.focusPeople
      }
      if (focusData.otherPeople) {
        this.otherPeople = focusData.otherPeople
      }
      if (this.directReports && this.directReports.length > 0) {
        this.isManager = true
      }

      let allFocus = []
      let extendedDirectReports = []
      if (focusData.manager) {
        allFocus.push(focusData.manager)
      }
      if (focusData.directReports) {
        allFocus = allFocus.concat(focusData.directReports)
        extendedDirectReports = extendedDirectReports.concat(focusData.directReports)
      }
      if (focusData.pendingManagers) {
        allFocus = allFocus.concat(focusData.pendingManagers)
      }
      if (focusData.focusPeople) {
        allFocus = allFocus.concat(focusData.focusPeople)
        let indirectReports = focusData.focusPeople.filter(fp => fp.isDirectRelation && fp.status == 2)
        if (indirectReports.length > 0) {
          extendedDirectReports = extendedDirectReports.concat(indirectReports)
        }
      }
      let hasAzureRelation = allFocus.filter(user => {
        return !('focusType' in user)
      })
      if (hasAzureRelation.length > 0) {
        this.hasAzureRelation = true
      }

      this.extendedDirectReports = extendedDirectReports
      this.allFocusPeopleSet = true
      //this.allFocus = response.data.directReports.concat([response.data.manager]).concat(response.data.focusPeople);
    }

    // Load Company Resources
    this.allCompanyResourcesSet = false
    const companyResourceData = jsonInfo.companyResources

    this.companyResources = companyResourceData
    this.allCompanyResourcesSet = true

    // this.activityRemindDate = user.activityRemindDate
    this.hasDismissedBanner = user.hasDismissedBanner
    this.hasDismissedTaskBanner = user.hasDismissedTaskBanner
    this.hasSeenOnboardingModal = user.hasSeenOnboardingModal
    this.showGetStartedPage = user.showGetStartedPage
    this.hasSeenGetStartedPage = user.hasSeenGetStartedPage
    this.hasDismissedGoalBanner = user.hasDismissedGoalBanner
    this.isManager = user.isManager
    this.determinedDates = user.determinedDates
    this.impersonateMode = user.impersonateMode
    this.demoMode = user.demoMode
    this.impersonateOldUser = user.impersonateOldUser
    this.hasImpersonationRights = user.hasImpersonationRights
    this.hasAdminConsent = user.hasAdminConsent
    if (moment().diff(moment(user.createdAt), 'hour', true) < 1) {
      //@ts-ignore
      if (window.clarity) {
        //@ts-ignore
        window.clarity('set', 'usertype', 'newuser')
      }
    }
  }

  @computed get getScopeArray() {
    const scopeArrayPropery = responsibleTypeStringToScopeArrayPropertyObj[this.responsibleType]
    return this[scopeArrayPropery]
  }
}
