import * as React from 'react'
import {MEETING_RESPONSES, VIEW_MODES} from '../constants/meetingConstants'
import i18n  from 'i18next';

const getUrlParameter = (name, searchString) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  let results = regex.exec(searchString)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

const getViewModeName = (viewMode) => {
  switch (viewMode) {
    case VIEW_MODES.AGENDA:
      return i18n.t('meetingHelper_agendaView', 'Agenda view')
    case VIEW_MODES.WORK_WEEK:
      return i18n.t('meetingHelper_workWeekView', 'Work week view')
    case VIEW_MODES.WEEK:
      return i18n.t('meetingHelper_weekView', 'Week view')
    default:
      return i18n.t('meetingHelper_noView', 'No view mode')
  }
} 

const getMeetingResponse = (item, userStore) => {
  let myself = userStore.mail
  let isOrganizer = myself === item.organizer?.emailAddress?.address
  if (isOrganizer) {
    return MEETING_RESPONSES.ACCEPTED
  }

  let response = item?.attendees?.filter(function (e) {
    return e.emailAddress?.address === myself
  }) ?? []

  if (response.length === 0) {
    return MEETING_RESPONSES.NOT_RESPONDED
  }

  switch (response[0]?.status?.response) {
    case 'accepted':
      return MEETING_RESPONSES.ACCEPTED
    case 'tentativelyAccepted':
      return MEETING_RESPONSES.TENTATIVE
    case 'declined':
      return MEETING_RESPONSES.DECLINED
    default:
      return MEETING_RESPONSES.NOT_RESPONDED
  }
}

export { getUrlParameter, getViewModeName, getMeetingResponse }
