import { action, observable } from 'mobx'
import axios from 'axios'

/*
  feedbacks, tasks gibi sayfalarda groupedList e verdigimiz array leri filtreleme deistigi gibi kolayca degistirebilmek icin yapildi
  + ornegin GroupMenu de Template i sectigimiz anda setFeedbackGroup feedbackGroup u 'Template' Yapicak ve
    feedbacks de Template nin array i groupedList e verilecek.
*/

export default class GroupingStore {
  @observable feedbackGroup: String = 'Person'
  @observable feedbackChange: boolean = false
  @observable tasksGroup: any = {
    includeCompleted: false,
    groupBy: { due: false, person: false, status: true },
    tasksChange: false,
    cb: () => {}
  }
  @observable goalsGroup: any = {
    groupBy: { default: true, date: false },
    includeCompleted: true
  }
  @observable surveyGroup: any = {
    groupBy: 'cycle'
  }
  @observable reviewGroup: any = {
    groupBy: 'cycle'
  }
  @observable goalSearchText: ''
  @observable taskSearchText: ''
  @observable meetingSearchText: ''
  @observable surveySearchText: ''
  @observable reviewSearchText: ''
  @observable isSearch: boolean
  @observable isGoalSearchEmpty: boolean = true
  @observable isTaskSearchEmpty: boolean = true
  @observable isMeetingSearchEmpty: boolean = true
  @observable isSurveySearchEmpty: boolean = true
  @observable isReviewSearchEmpty: boolean = true

  @action setFeedbackGroup = group => {
    axios
      .post('/api/common/updateGroupby', {
        group: group,
        currentModule: 'feedback'
      })
      .then(response => {
        console.log('Response :', response)
      })

    this.feedbackGroup = group
    this.setFeedbackChange(true)
  }

  @action setFeedbackChange = bool => {
    this.feedbackChange = bool
  }

  @action setTasksSearchText = text => {
    this.taskSearchText = text
    this.isSearch = true
    this.isTaskSearchEmpty = false
    if (text === '') {
      this.isTaskSearchEmpty = true
    }
  }
  @action setSurveySearchText = text => {
    this.surveySearchText = text
    this.isSearch = true
    this.isSurveySearchEmpty = false
    if (text === '') {
      this.isSurveySearchEmpty = true
    }
  }
  @action setReviewSearchText = text => {
    this.reviewSearchText = text
    this.isSearch = true
    this.isReviewSearchEmpty = false
    if (text === '') {
      this.isReviewSearchEmpty = true
    }
  }

  @action setTasksIncludeCompleted = bool => {
    this.tasksGroup.includeCompleted = bool
    this.tasksGroup.tasksChange = true
    axios.post('/api/common/updateIncludeCompleted', { includeCompleted: bool, currentModule: 'tasks' })
  }

  @action setGoalsSearchText = text => {
    this.goalSearchText = text
    this.isSearch = true
    this.isGoalSearchEmpty = false
    if (text === '') {
      this.isGoalSearchEmpty = true
    }
  }

  @action setMeetingsSearchText = text => {
    this.meetingSearchText = text
    this.isSearch = true
    this.isMeetingSearchEmpty = false
    if (text === '') {
      this.isMeetingSearchEmpty = true
    }
  }

  @action setSearchFalse = () => {
    this.isSearch = false
  }

  @action setTasksGroupBy = type => {
    axios
      .post('/api/common/updateGroupby', {
        type: type,
        currentModule: 'tasks'
      })
      .then(response => {})
    this.tasksGroup.groupBy = type.groupBy
    this.tasksGroup.includeCompleted = type.includeCompleted
    this.setTasksChange(true)
    this.tasksGroup.cb()
  }

  @action setSurveyGroupBy = group => {
    axios
      .post('/api/common/updateGroupby', {
        group: group,
        currentModule: 'survey'
      })
      .then(response => {})


    this.surveyGroup.groupBy = group
  }

  @action setReviewGroupBy = group => {
    axios
      .post('/api/common/updateGroupby', {
        group: group,
        currentModule: 'review'
      })
      .then(response => {})

    this.reviewGroup.groupBy = group
  }

  @action setTasksGroupCb = cb => {
    this.tasksGroup.cb = cb
  }

  @action setTasksChange = b => {
    this.tasksGroup.tasksChange = b
  }

  @action setGoalsGroupBy = newGroup => {
    this.goalsGroup = newGroup
  }

  @action setInitialGroupBy(determinedDates, cb) {
    if (determinedDates.task !== undefined) {
      if (determinedDates.task.groupBy !== undefined) {
        if (determinedDates.task.groupBy.groupBy !== undefined) {
          this.tasksGroup.groupBy = determinedDates.task.groupBy.groupBy
        }
        if (determinedDates.task.includeCompleted !== undefined) {
          this.tasksGroup.includeCompleted = determinedDates.task.includeCompleted
        }
      }
    }
    if (determinedDates.feedback !== undefined) {
      if (determinedDates.feedback.groupBy !== undefined) {
        if (determinedDates.feedback.groupBy.group !== undefined) {
          this.feedbackGroup = determinedDates.feedback.groupBy.group
        }
      }
    }
    if (determinedDates.survey !== undefined) {
      if (determinedDates.survey.groupBy !== undefined) {
        this.surveyGroup.groupBy = determinedDates.survey.groupBy
      }
    }
    if (determinedDates.review !== undefined) {
      if (determinedDates.review.groupBy !== undefined) {
        this.reviewGroup.groupBy = determinedDates.review.groupBy
      }
    }
  }
}
