import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import en from '../assets/public/locales/en/translation.json'
import de from '../assets/public/locales/de/translation.json'
import fr from '../assets/public/locales/fr/translation.json'
import es from '../assets/public/locales/es/translation.json'
import pt from '../assets/public/locales/pt/translation.json'
import tr from '../assets/public/locales/tr/translation.json'

// import Backend from 'i18next-locize-backend'

console.log('en', en)
i18next
  .use(initReactI18next)
  // .use(Backend)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: true,
    saveMissing: true,
    resources: {
      en: { translation: en },
      de: { translation: de },
      fr: { translation: fr },
      es: { translation: es },
      pt: { translation: pt },
      tr: { translation: tr }
    },
    // backend: {
    //   loadPath: 'assets/public/locales/{{lng}}/translation.json',
    //   addPath: 'assets/public/locales/{{lng}}/translation.json'
    // },
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
      console.log('Missing key:', lngs, ns, key, fallbackValue)
    },
    interpolation: {
      escapeValue: false
    }
  })

export default i18next
