import { action, observable } from 'mobx'

/**
 * bunun amaci userpage den show more ile gidilen sayfalarda kimin userpage sinden gelmis user hakkinda bilgileri edinmek
 * ilerde farkli bilgiler lazim olursada request atmadan userpage.tsx de buraya set ederek gidilen sayfalardan erisilebilir.
 */
export default class UserPageStore {
  @observable userData

  @action setUserData = d => {
    this.userData = d
  }
}
