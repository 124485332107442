import * as React from 'react'
import { Text, Spinner } from 'office-ui-fabric-react'
import ModalStore from '../stores/modalStore'
import UserStore from '../stores/userStore'
import CloseIconButton from './CloseIconButton'
import { inject, observer } from 'mobx-react'
import i18n from 'i18next'

interface UserOption {
  id: string
  role: string
  jobTitle: string
  displayName: string
  // Add profileImageUrl in your user options
  profileImageUrl: string
}

const userOptionStyle = {
  box: {
    margin: '10px',
    minWidth: '160px',
    maxWidth: '160px',
    cursor: 'pointer',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0 0 2px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.14)',
    transition: '0.3s',
    textAlign: 'center' as const,
    display: 'flex' as const,
    flexDirection: 'column' as 'column',
    alignItems: 'center' as const
  },
  boxSelected: {
    margin: '10px',
    minWidth: '160px',
    maxWidth: '160px',
    cursor: 'pointer',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0px 0px 2px rgba(226, 229, 247, 1), 0px 1px 5px 4px rgb(191 200 255)',
    transition: '0.3s',
    textAlign: 'center' as const,
    display: 'flex' as const,
    flexDirection: 'column' as 'column',
    alignItems: 'center' as const
  },
  boxNotSelected: {
    margin: '10px',
    minWidth: '180px',
    maxWidth: '180px',
    cursor: 'pointer',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0 0 2px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.14)',
    transition: '0.3s',
    textAlign: 'center' as const,
    display: 'flex' as const,
    flexDirection: 'column' as 'column',
    alignItems: 'center' as const
  },
  image: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    marginBottom: '10px'
  },
  text: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginTop: '5px'
  },
  secondaryText: {
    fontSize: '12px'
  },
  roleText: {
    fontSize: '12px',
    marginTop: '10px'
  }
}

interface DemoUserModalProps {
  modalStore: ModalStore
  userStore: UserStore
  onUserSelected: (displayName: string) => void
}

interface DemoUserModalState {
  loading: boolean
}

@inject('modalStore', 'userStore')
@observer
export class DemoUserModal extends React.Component<DemoUserModalProps, DemoUserModalState> {
  constructor(props: DemoUserModalProps) {
    super(props)
    this.state = {
      loading: false
    }
  }

  userOptions: UserOption[] = [
    // Example users, replace with real data
    {
      id: '46333e40-b971-4cd3-8841-849cd2f7dcf1',
      role: 'Teamflect admin',
      jobTitle: 'Head of HR',
      displayName: 'Jordan Taylor',
      profileImageUrl: 'https://teamblee.blob.core.windows.net/teamflect/jordanTaylor.png'
    },
    {
      id: '8204b3e2-e2d4-414b-a754-80daeed1b96c',
      role: 'People manager',
      jobTitle: 'Head of Marketing',
      displayName: 'Michelle Williams',
      profileImageUrl: 'https://teamblee.blob.core.windows.net/teamflect/michelleWilliams.png'
    },

    {
      id: '82c9c655-d3c8-43d3-bd05-f2b10998c732',
      role: 'Individual contributor',
      jobTitle: 'Marketing Coordinator',
      displayName: 'Jamal Davis',
      profileImageUrl: 'https://teamblee.blob.core.windows.net/teamflect/jamalDavis.png'
    }
  ]

  selectUser = (displayName: string) => {
    this.setState({ loading: true })
    setTimeout(() => {
      this.props.onUserSelected(displayName)

      console.log('User selected:', displayName)
      this.props.modalStore.closeModal()
      this.setState({ loading: false })
    }, 3000)
  }

  renderUserOptions = () => {
    const selectedUser = this.props.userStore.oid
    const demoMode = this.props.userStore.demoMode
    return this.userOptions.map(user => (
      <div
        key={user.id}
        style={demoMode && user.id === selectedUser ? userOptionStyle.boxSelected : userOptionStyle.boxNotSelected}
        onClick={() => this.selectUser(user.displayName)}
        onMouseEnter={e => (e.currentTarget.style.transform = 'scale(1.05)')}
        onMouseLeave={e => (e.currentTarget.style.transform = 'scale(1)')}
      >
        <img src={user.profileImageUrl} alt={user.displayName} style={userOptionStyle.image} />
        <Text style={userOptionStyle.text}>{user.displayName}</Text>
        <Text style={userOptionStyle.secondaryText}>{user.jobTitle}</Text>
        <Text style={userOptionStyle.roleText}>
          <Text style={{ fontWeight: 600 }}>Role:</Text> {user.role}
        </Text>
      </div>
    ))
  }

  render() {
    const { modalStore } = this.props

    return (
      <div style={{ padding: 20, paddingBottom: 30, position: 'relative' }}>
        <div style={{ position: 'absolute', top: 20, right: 20 }}>
          <CloseIconButton onClick={() => modalStore.closeModal()} />
        </div>
        <Text className='modal-header-title' block>
          Select Demo User
        </Text>
        {this.state.loading ? (
          <div
            className='bia'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <img style={{ width: '160px', height: '160px' }} src='assets/images/eclipse.gif' />
            {/* <Text>{i18n.t('DemoUserModal_SelectingUser', 'Selecting Demo User...')}</Text> */}
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>{this.renderUserOptions()}</div>
        )}
      </div>
    )
  }
}

export default DemoUserModal
