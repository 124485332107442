import * as React from 'react'
import { useEffect, useState } from 'react'
import cn from 'classnames'
import { getQueryString } from '../helpers/getQueryString'
import { app } from '@microsoft/teams-js'
import i18n from 'i18next'

type Props = {}

const SuspanseFallbackPage = (props: Props) => {
  const [isDark, setIsDark] = useState(false)

  useEffect(() => {
    app.getContext().then(context => {
      if (context?.app?.theme === 'dark') {
        setIsDark(true)
      } else {
        setIsDark(false)
      }
    })
  }, [])

  return (
    <section className={cn('ms-welcome__progress ms-u-fadeIn500 fallback-page', { dark: isDark })}>
      <img
        height='50'
        width='210'
        style={{ marginBottom: 130 }}
        src={
          isDark
            ? 'https://teamblee.blob.core.windows.net/teamblee/teamflectwhitesmall.svg'
            : '/assets/logo-filled-small.png'
        }
      />
      <span className='loadingText'>{i18n.t('SuspenseFallbackPage_Loading', 'Loading...')}</span>
      {/* <span className='loadingText'>Loading...</span> */}
    </section>
  )
}

export default SuspanseFallbackPage
