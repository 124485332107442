const feedBacks: string = 'feedback'
const reviews: string = 'reviews'
const review: string = 'review'
const surveys: string = 'surveys'
const survey: string = 'survey'
const tasks: string = 'tasks'
const oneOnOnes: string = '1on1s'
const notes: string = 'notes'
const recognitions: string = 'recognitions'
const emails: string = 'emails'
const pulse: string = 'pulse'
const goals: string = 'goals'
const meetings: string = 'meetings'
export {
  feedBacks,
  tasks,
  oneOnOnes,
  notes,
  recognitions,
  emails,
  pulse,
  goals,
  reviews,
  review,
  meetings,
  surveys,
  survey
}
