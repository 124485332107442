import { action, observable } from 'mobx'

/**
 * ekleme sebebim feedback given ve fb count u set edip rahatlikla ulasabilmek her yerden
 * gelecekte feedback data si belki store ile global yonetilir onada bir baslangic.
 */

export default class feedbackStore {
  @observable pendingGivenFbCount: number = 0
  @observable pendingReceivedFbCount: number = 0

  @action setGivenPendingCount = c => {
    this.pendingGivenFbCount = c
  }

  @action setReceivedPendingCount = c => {
    this.pendingReceivedFbCount = c
  }
}
