import axios from 'axios'
import * as moment from 'moment'
import { getProfilePicV } from './GraphHelpers'

const getProfilePicWithOptimize = async (userStore, upn, dimensions = '96x96') => {

  if (!upn) return null
  let profilePic = null

  if (dimensions === '96x96') {
    profilePic = userStore.getProfilePic(upn)
  }
  const other = userStore.otherPics.find(o => o.upn === upn)
  
  if (other) {
    return other.pic
  }

  if (!profilePic) {
    try {
      const tokenExpires = moment(userStore.graphTokenExpires).subtract(5,"minutes")
      if(tokenExpires.isBefore(moment())){
        const { data } = await axios.get('/api/common/getNewTokens')
        userStore.setNewToken(data.graphAccessToken, data.graphTokenExpires)
      }
      profilePic = await getProfilePicV(userStore.graphAccessToken, upn, dimensions) 
      userStore.addPic(upn, profilePic)
    } catch (e) {
      return ''
    }
  }
  return profilePic
}

export default getProfilePicWithOptimize
