import { action, observable } from 'mobx'

/*
  ana sebebi oneonone back buttonu icin prevUrl eger site disiysa home a yonlendirilmesi icin
  ileridede baska durumlar olusa yine o durumlardada kullanilabilir.
*/

export default class LocationStore {
  @observable prevLocationForOneOnOne = null

  @action setPrevLocationForOneOnOne = location => {
    this.prevLocationForOneOnOne = location
  }
}
