const isMyDr = (userStore, userUPN) => {
  if (userStore.directReports.find(u => u.userPrincipalName === userUPN)) {
    return true
  } else if (userStore.focusPeople.find(u => u.userPrincipalName === userUPN && u.isDirectRelation)) {
    return true
  }
  return false
}

const isMyDrWithId = (userStore, userId) => {
  if (userStore.directReports.find(u => u.id === userId || u.oid === userId)) {
    return true
  } else if (userStore.focusPeople.find(u => (u.id === userId || u.oid === userId) && u.isDirectRelation)) {
    return true
  }
  return false
}

const isGoalHasMyDirectReport = (userStore, goal) => {
  return goal.owners.some(u => isMyDr(userStore, u.userPrincipalName))
}

const isMyManager = (userStore, userUPN) => {
  if (userStore.manager) {
    return userStore.manager.userPrincipalName === userUPN //TODO
  } else {
    return false
  }
}

const whoIs = (userStore, userUPN) => {
  if (isMyDr(userStore, userUPN)) return 'directReport'
  if (isMyManager(userStore, userUPN)) return 'manager'
  return 'focus'
}

export { isMyDr, whoIs, isMyManager, isGoalHasMyDirectReport, isMyDrWithId }
